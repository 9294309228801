import draggable from 'vuedraggable'
import FaqModal from './FaqModal'
import faqServices from '../../../services/faq'

export default {
    components: {
        draggable,
        FaqModal
    },
    data() {
        return {
            isDragging: false,
            delayedDragging: false,
            items: [],
            faqModalKey: 0
        }
    },
    mounted(){
        this.fetchData()
    },
    computed: {
        dragOptions() {
            return {
                animation: 200,
                group: 'description',
                ghostClass: 'ghost',
                disabled: this.$store.state.loading
            }
        }
    },
    watch: {
        isDragging(newValue) {
            if (newValue) {
                this.delayedDragging = true
                return
            }
            this.$nextTick(() => {
                this.delayedDragging = false
            })
        }
    },
    methods: {
        endMove() {
            this.isDragging = false
            this.items.forEach((el, i) => {
                el.order_num = i
            })
            this.$nextTick(()=>{
                this.updateFaqsOrder()
            })
        },
        updateFaqsOrder() {
            faqServices.updateFaqsOrder(this.items).then(resp=>{
                if (!resp.error) {
                    this.onCompleted('UPDATE')
                }
            })
        },
        fetchData() {
            faqServices.findFaqs().then(resp=>{
                if (!resp.error) {
                    this.items = resp.data.d
                }
            })
        },
        onCompleted(type) {
            if (type == 'UPDATE') {
                this._showToast('Updated successfully!')
            }
            if (type == 'INSERT') {
                this._showToast('Added successfully!')
            }
            if (type == 'DELETE') {
                this._showToast('Deleted successfully!')
            }
            this.fetchData()
        },
        addMoreClick() {
            this.faqModalKey += 1
            this.$nextTick(()=>{
                this.$refs.faqModal.forceRerenderInsert()
            })
        },
        updateFaq(item) {
            this.faqModalKey += 1
            this.$nextTick(()=>{
                this.$refs.faqModal.forceRerenderUpdate(item)
            })
        },
        onMove({ relatedContext, draggedContext }) {
            const relatedElement = relatedContext.element
            const draggedElement = draggedContext.element
            return (
                (!relatedElement || !relatedElement.fixed) && !draggedElement.fixed
            )
        },
        deleteFaq(item) {
            this._showConfirm('You are about to delete this FAQ. This cannot be undone').then(confirm=>{
                if (confirm) {
                    faqServices.deleteFaq(item).then(resp=>{
                        if (!resp.error) {
                            this.onCompleted('DELETE')
                        }
                    })
                }
            })
        }
    }
}